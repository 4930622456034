(function ($) {
  Drupal.behaviors.headerMenuSearch = {
    attach: function (context) {
      var that = this;
      const landscapeUp = 1024;

      that.$trigger = $('.js-nav-category-search-trigger');

      if (Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr22_refresh) {
        that.$trigger.off('click').on('click', function (e) {
          e.preventDefault();
          if (window.innerWidth < landscapeUp) {
            $(document).trigger('search:toggle');
          } else {
            $(document).trigger('search:close');
          }
        });

        that.$trigger.off('mouseover').on('mouseover', function (e) {
          if (window.innerWidth >= landscapeUp) {
            e.preventDefault();
            $(document).trigger('search:open');
          }
        });
      } else {
        that.$trigger.off('click').on('click', function (e) {
          e.preventDefault();
          $(document).trigger('search:toggle');
        });
      }
    }
  };
})(jQuery);
